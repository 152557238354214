import { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Image from 'next/image';

import Text from '@hiredigital/ui/Text/Text';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import { Slider } from '@contentco/react-scroll-snap-slider';

import { Section, SectionContainer } from '@components/Section';
import InteractiveButton from '@components/InteractiveButton/InteractiveButton';

import Styles from './ClientTestimonial.module.scss';

const users = [
  {
    name: 'Kamal Laungani',
    role: 'Global Developer Ecosystem Lead',
    company: 'Affinidi',
    logo: 'affinidi',
    photo: { url: '/img/testimonials/kamal.webp' },
    quote: `Hiring talent was way faster with Hire Digital. We could seamlessly hire marketers with the credibility we wanted in the B2D sector.`,
  },
  {
    name: 'Alicia Cheng-Collette',
    role: 'Employer Branding Marketing',
    company: 'Philips',
    logo: 'philips',
    photo: { url: '/img/testimonials/alicia2.webp' },
    quote: `Working with an experienced team of talent and consistent quality in output and it has saved time in the number of briefings and revisions. I think our content has improved largely due to working with Hire Digital.`,
  },
  {
    name: 'Patrick Matthews',
    role: 'Marketing & Communications Manager',
    company: 'BennBridge',
    logo: 'bennbridge',
    photo: { url: '/img/testimonials/patrick.webp' },
    quote: `The Digital Marketer Hire Digital matched us with has been a fantastic support, going out of his way to assist with many different tasks, from marketing technology set up, to supporting with strategy and execution, and I can’t recommend him highly enough. Thanks to Hire Digital for connecting us with such a valuable support.`,
  },
  {
    name: 'Jackson Sim',
    role: 'Cluster Director of Digital Marketing',
    company: 'Marriott',
    logo: 'marriott',
    photo: { url: '/img/testimonials/jackson2.webp' },
    quote: `Hire Digital makes it work every single time, and the quality of work is second to none. You can be assured that they will always deliver, regardless of what is sent to them.`,
  },
  {
    name: 'Bernice Tan',
    role: 'Regional Communications',
    company: 'Zuellig Pharma',
    logo: 'zuellig',
    photo: { url: '/img/testimonials/bernice2.webp' },
    quote: `Hire Digital has been a great help. We were able to work with capable talent who saved us a lot of time. The Hire Digital team ensures that we find good talent matches and they’ve been very responsive.`,
  },
];

let interval;

const Item = ({ user, isEnterprise }) => (
  <div className={Styles.container}>
    <div className={Styles.pictureContainer}>
      <Image
        src={user.photo?.url}
        alt={user.company}
        className={Styles.picture}
        width={800}
        height={800}
      />
    </div>
    <div className={classNames(Styles.itemContainer, isEnterprise && Styles.blueContainer)}>
      <div className={Styles.logoContainer}>
        <img
          src={`/logos/${isEnterprise ? 'white-cropped/' : 'color-cropped/'}${user.logo}.svg`}
          alt={user.company}
          loading='lazy'
          className={Styles.logo}
        />
        <div className={Styles.logoSpacer} />
      </div>
      <p className={Styles.quote}>{user.quote}</p>
      <div>
        <p className={Styles.name}>{user.name}</p>
        <span className={Styles.role}>{user.role}</span>
      </div>
    </div>
  </div>
);

const ClientTestimonial = ({ title, isEnterprise, hideHeader = false, data = users }) => {
  const ref = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideLength, setSlideLength] = useState();

  useEffect(() => {
    setSlideLength(data.length);
  }, [data]);

  useEffect(() => {
    if (slideLength > 1) {
      interval = setInterval(() => {
        handleNext();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [activeSlide]);

  useEffect(() => {
    ref?.current?.scrollToSlide(activeSlide);
  }, [activeSlide]);

  const slidesPerPageSettings = {
    mobileSmall: 1,
    mobileBig: 1,
    tablet: 1,
    desktop: 1,
  };

  const handleNext = () => {
    clearInterval(interval);
    setActiveSlide((activeSlide) => (activeSlide + 1) % slideLength);
  };

  const handlePrev = () => {
    clearInterval(interval);
    setActiveSlide(
      (activeSlide) => (activeSlide - 1 < 0 ? slideLength - 1 : activeSlide - 1) % slideLength
    );
  };

  return (
    <div>
      {!hideHeader && (
        <SectionContainer style={{ overflow: 'hidden' }} anchor='testimonials'>
          <Section>
            <div style={{ display: 'flex' }}>
              <Text
                element='h2'
                style={{ marginBottom: '32px' }}
                size={Text.Size.SUBHEADLINE_LARGE}
                type={Section.Type.NORMAL}
                color={isEnterprise ? Section.Color.WHITE : Section.Color.BLACK}>
                {title || `What Our Clients Say`}
              </Text>
              {slideLength > 1 ? (
                <div style={{ display: 'flex', marginLeft: 'auto' }}>
                  <IconDown
                    onClick={handlePrev}
                    className={classNames(
                      Styles.arrow,
                      Styles.left,
                      isEnterprise ? Styles.whiteArrow : Styles.blackArrow
                    )}
                  />
                  <IconDown
                    onClick={handleNext}
                    className={classNames(
                      Styles.arrow,
                      Styles.right,
                      isEnterprise ? Styles.whiteArrow : Styles.blackArrow
                    )}
                  />
                </div>
              ) : (
                ''
              )}
            </div>

            <Slider ref={ref} slidesPerPageSettings={slidesPerPageSettings} disableArrow>
              {data.map((user, index) => (
                <Item user={user} isEnterprise={isEnterprise} key={index} />
              ))}
            </Slider>

            <div className={Styles.seeMore}>
              <InteractiveButton
                type={
                  isEnterprise ? InteractiveButton.Type.WHITE : InteractiveButton.Type.BLUE_OUTLINE
                }
                href='/clients'>
                {`See Our Clients`}
              </InteractiveButton>
            </div>
          </Section>
        </SectionContainer>
      )}
    </div>
  );
};

ClientTestimonial.propTypes = {
  isEnterprise: PropTypes.bool,
};

export default ClientTestimonial;
